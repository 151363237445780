// @flow

import React from 'react';
import styled from 'styled-components';

import { BREAKPOINT } from '@latitude/core/utils/constants';

const confettiLeft = require('./images/confetti_arrows.png');
const confettiRight = require('./images/confetti_bricks.png');

type Props = {
  isBranded?: boolean
};

// const Title = styled.div`
//   color: #006aff;
//   border: 1px solid #006aff;
//   padding: 8px 16px;
//   border-radius: 4px;
//   margin-top: 16px;
//   display: inline-block;
//   &:hover {
//     background-color: #006aff;
//     border-color: #006aff;
//     color: white;
//   }
// `;

const Link = styled.div`
  display: flex;
  justify-content: center;
  ${({ isBranded }: { isBranded: boolean }) =>
    isBranded
      ? `background-image: url(${confettiLeft}), url(${confettiRight});
      background-position: 110px 110px,235px 30px;
      background-repeat: no-repeat,no-repeat;
      background-size: 30px 30px,30px 50px;
      @media (max-width: ${BREAKPOINT.MD}) {
        background-position: 110px 130px,235px 50px;
        padding-top: 20px;
      }
      `
      : ''};
`;

const PersonalLoanContract = ({ isBranded = false }: Props) => (
  <div className="row mt-md-5">
    <Link className="col-md-12" isBranded={isBranded}>
      <a
        href="https://assets.latitudefinancial.com/legals/terms-conditions/latitude-for-kiwibank-terms.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          borderColor: '#000',
          color: '#000'
        }}
      >
        <img
          // isBranded
          alt="Kiwibank Personal Loan Contract Terms & Conditions"
          className="mb-3"
          style={{
            display: 'block',
            width: isBranded ? '80px' : '215px'
          }}
          src={require('./images/kb-personal-loans-tc.png')}
        />
        Download Terms &amp; Conditions
      </a>
    </Link>
  </div>
);

export default PersonalLoanContract;
