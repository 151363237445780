// @flow

import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Box } from '@latitude/box';
import { FeaturesSlider } from '@latitude/features-slider';
import { HorizontalRule } from '@latitude/horizontal-rule';
// import { StickyCta } from '@latitude/sticky-cta';
import { Heading3 } from '@latitude/heading';
import { StickyNavigation } from '@latitude/sticky-navigation';
import {
  BREAKPOINT,
  COLOR,
  PADDING
} from '@latitude/core/utils/constants';
import FeatureTiles from '../../components/FeatureTiles/FeatureTiles'
import ImportantInformation from '../../components/ImportantInformation/ImportantInformation';
import Lframe from '../../components/Lframe/Lframe';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import ConfettiFramedContainerKiwibank from '../../components/ConfettiFramedContainer/ConfettiFramedContainerKiwibank';
// import { SOFT_QUOTE_KIWIBANK_URL } from '../../utils/softQuoteUtil';
import { CardGroup, CardGroupItem } from '../../components/CardGroup/CardGroup';
import LoanCardBranded from '../../components/LoanCard/LoanCardBrandedKb';
import Layout from '../../components/layout-kiwibank';
import PageData from '../../data/pages/personal-loan-kiwibank.json';
import MobileAppInstallPrompts from '../../components/MobileAppInstallPrompts/MobileAppInstallPrompts';
import { Table, Row, Cell } from '../../components/TableNew/TableNew';
import CalculatorTile from '../../components/Calculators/CalculatorTile';
import { featuresSliderIE11Fix } from '../../utils/stylesUtils';
import { LOAN_PURPOSE } from '../../utils/constants';
import EstimateRateWidgetSectionKiwibank from '../../components/EstimateRateWidget/EstimateRateWidgetSectionKiwibank';
import useApplyUrl from '../../hooks/useApplyUrl';
import Faq from '../../components/Faq/Faq';
import FaqData from '../../data/json/faq-personal-loans.json';
import FooterKiwibank from './_footer';
import HowToApplyEligibility from '../../components/HowToApplyEligibility/HowToApplyEligibilityKiwibank';
import SoftQuoteContext from '../../context/SoftQuoteContext';
import PersonalLoanContract from '../../components/PersonalLoanContract/PersonalLoanContract';

/*
 * Kiwibank Personal Loan Page
 */
const Index = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const { applyUrl, setApplyUrl  } = useApplyUrl();

  return (
    <Layout noFooter>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer personal-loan-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://kiwibank.latitudefinancial.com/personal-loans/"
            />
            <title>
              Personal Loans NZ | Latitude for Kiwibank
            </title>
            <meta
              name="description"
              content="Need a Personal Loan in New Zealand for a new car, home renovation, travel or consolidate debt?"
              noIndex
            />
            <meta
              name="keywords"
              content="Personal Loan,Personal Loan NZ,Personal Loans,Apply for a Personal Loan,Kiwibank"
            />
            <meta
              name="robots"
              content="noIndex"
            />
          </Helmet>

          <Lframe personalLoansKiwibank hasMobileStickyCta />
          <HeroBranded
            title="Personal Loans"
            text={
              <>
                With a personal loan you can make things happen. Plus, you can pay your loan off faster to save on interest, with no early repayment fees. Check your eligibility in just 2 minutes online &ndash; why not do it right now?
              </>
            }
            buttonProps={{
              href: '/loan-calculator/',
              children: 'Calculate your repayments',
              trackId: 'hero-calculate-your-repayments',
            }}
            trackEventData={{
              category: 'cta',
              action: 'quote-link'
            }}
          />
          {/* Start: Hero with ribbon / no image */}
          <SoftQuoteContext.Provider value={{ applyUrl, setApplyUrl }}>
            {/* <div className="d-lg-none d-xl-none">
              <StickyCta
                href={applyUrl}
                trackId="sticky-check-your-eligibility"
                text="Check your eligibility"
              />
            </div> */}
            {/* End: Hero with ribbon / no image */}

            <div className="d-none d-lg-block">
              <StickyNavigation
                items={PageData.nav}
                phoneNumber={PageData.content.phoneNumberKiwi}
                isSticky={isNavSticky}
                onStickyNavStateChange={setIsNavSticky}
                // ctaHref={applyUrl}
                // ctaTarget="_self"
                // ctaText="Check your eligibility"
                offsetElem="[data-sticky-navigation-offset]"
                trackId="sticky-nav-check-your-eligibility"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  label: 'Check your eligibility',
                  location: 'Sticky Navigation'
                }}
                trackProductId={['PLNZLF-WEB']}
              />
            </div>

            <EstimateRateWidgetSectionKiwibank
              purpose={LOAN_PURPOSE.SELECT_PURPOSE}
              trackProductId={['PLNZLF-WEB']}
            />
          </SoftQuoteContext.Provider>

          <AnalyticsLocationProvider location="Personal Loan">
            <Box.Section
              backgroundColor={COLOR.GREY6}
              id="rates"
            >
              <Heading3
                css={`
                  font-size: 24px;
                  color: ${COLOR.BLACK};
                  text-align: center;
                  @media (min-width: ${BREAKPOINT.LG}) {
                    font-size: 32px;
                  }
                `}
              >
                Personal Loan
              </Heading3>
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-sm-8 col-md-7 col-lg-12">
                  <CardGroup
                    css={`
                      max-width: 1100px;
                      margin: auto;
                    `}
                  >
                    <CardGroupItem col={2}>
                      <LoanCardBranded
                        kiwibank
                        icon="snapfingers"
                        isBranded
                        className="h-100"
                        title="Personal Loan"
                        description={
                          <>
                            Consolidating debts? Buying a car? Getting married or taking a trip? Let&apos;s do it with a Personal Loan.
                          </>
                        }
                        rateBoxType="kiwibankPersonal"
                        rateBoxType2="kiwibankHome"
                        rateBoxFullWidth
                        listItems1={[
                          'Loan amounts from $2,000',
                          'Loan terms from 1-7 years',
                          'Fixed rate for the life of the loan',
                          'No early repayment fees',
                          'Establishment fee of $199 applies'
                        ]}
                        // button2={{
                        //   href: SOFT_QUOTE_KIWIBANK_URL,
                        //   trackId: 'personal-loan-check-your-eligibility',
                        //   text: 'Check your eligibility',
                        //   target: '_self',
                        //   trackEventData: {
                        //     category: 'cta',
                        //     action: 'quote-link',
                        //     location: 'Personal Loan'
                        //   }
                        // }}
                      />
                    </CardGroupItem>
                  </CardGroup>
                  <div>
                    <PersonalLoanContract />
                  </div>
                </div>
              </div>
            </Box.Section>
          </AnalyticsLocationProvider>

          <CalculatorTile id="calculator" kiwibank />

          <FeaturesSlider
            heading="Why choose a Personal Loan?"
            id="why-us"
            className="why-choose bg-f8f8f8"
            data={PageData.whyChooseKiwibank}
            css={featuresSliderIE11Fix}
          />
          <HorizontalRule />
          <LoanDetailsSection />

          <HowToApplyEligibility />

          <FeatureTiles
            id="apply"
            heading={PageData.content.applyKiwibank.title}
            tiles={PageData.content.applyKiwibank.tiles}
          />

          <Faq data={FaqData} autoHeightOnDesktop />

          <ImportantInformation
            data={require('../../data/pages/personal-loan-kiwibank.json')}
            sectionOneColummClasses="col-10 offset-1"
          />

        </Box>
      </main>
      <FooterKiwibank />
    </Layout>
  );
};

const LoanDetailsSection = () => (
  <ConfettiFramedContainerKiwibank heading="Let’s talk loan details" id="details">
    <Box padding={PADDING.P24}>
      <Table>
        <tbody>
          <Row>
            <Cell>Fixed rate</Cell>
            <Cell>For the life of the loan</Cell>
          </Row>
          <Row>
            <Cell>Loan amounts</Cell>
            <Cell>From $2,000</Cell>
          </Row>
          <Row>
            <Cell>Repayments</Cell>
            <Cell>Weekly, fortnightly, or monthly</Cell>
          </Row>
          <Row>
            <Cell>Loan terms</Cell>
            <Cell>1-7 years</Cell>
          </Row>
          <Row>
            <Cell>No early repayment fees</Cell>
            <Cell>Pay your loan off sooner, at no extra charge!</Cell>
          </Row>
          <Row>
            <Cell>Establishment fee</Cell>
            <Cell>$199</Cell>
          </Row>
          <Row>
            <Cell css="border-bottom: none">Late fee</Cell>
            <Cell css="border-bottom: none">$35 if you don&apos;t make the minimum repayment by your agreed due date</Cell>
          </Row>
        </tbody>
      </Table>
    </Box>
  </ConfettiFramedContainerKiwibank>
);

export default Index;
